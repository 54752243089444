import React, { useState } from 'react';
import { Tooltip } from "react-tooltip";

import SvgIcon from "../Icons";
import DropdownWithSearch from "../Forms/DropdownWithSearch";
import BasicDropdown from "../Forms/BasicDropdown";
import NumberDropdown from "../Forms/NumberDropdown";

import './index.sass';


const PlacesSearch = ({ categories, regionsOptions, wrapperRef, updateUrl }) => {

  console.log('regionsOptions--->', regionsOptions);

  const [numberOfGuests, setNumberOfGuests] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [searchRegionsResults, setSearchRegionsResults] = useState([]);
  const [searchDistrictsResults, setSearchDistrictsResults] = useState([]);
  const [noSearchResults, setNoSearchResults] = useState(false);

  const typesOptions = categories && categories.length && categories.map(categoryGroup => ({
    categories: categoryGroup.categories,
    icon: categoryGroup.key,
    id: categoryGroup.id,
    label: categoryGroup.name,
  }));


  const selectRegion = (region) => {
    setSelectedRegion(region.slug);
    setInputValue(region.name);

    if (updateUrl) {
      updateUrl({ regions: region.id })
    }
  };


  const selectDistrict = (district) => {
    setSelectedDistrict(district.slug);
    setSelectedRegion(district.regionSlug);
    setInputValue(district.name);
  };


  const handleSearchInput = (value) => {
    setInputValue(value);
    setSelectedRegion(null);
    setSelectedDistrict(null);

    console.log('value--->', typeof value, value);

    const clearString = (string) => string.replace(/[^a-z\d\s]+/gi, "").toLowerCase();
    const searchByFirstLetter = value.length === 1;
    const query = clearString(value);
    let foundRegions = [];
    let foundDistricts = [];

    if (query) {

      regionsOptions.map(region => {
        const { districts, name, slug } = region;

        if (searchByFirstLetter) {
          (slug.split('')[0].toLowerCase() === query || name.split('')[0].toLowerCase() === query)
            && foundRegions.push(region);

          districts.map(district => {
            const hasDistrictPostalCode = district.postalCodes.find(postalCode => value.includes(postalCode));

            (district.name.split('')[0].toLowerCase() === query || district.slug.split('')[0].toLowerCase() === query || hasDistrictPostalCode)
              && foundDistricts.push({ ...district, regionSlug: region.slug })
          });
        } else {
          (clearString(slug).includes(query) || clearString(name).includes(query))
            && foundRegions.push(region);

          districts.map(district => {
            const hasDistrictPostalCode = district.postalCodes.find(postalCode => value.includes(postalCode));

            (clearString(district.slug).includes(query) || clearString(district.name).includes(query) || hasDistrictPostalCode)
              && foundDistricts.push({ ...district, regionSlug: region.slug });
          });
        }
      });

      foundRegions.sort((a, b) => b.placesCount - a.placesCount);
      foundDistricts.sort((a, b) => b.placesCount - a.placesCount);
      setSearchRegionsResults(foundRegions.slice(0, 5));
      setSearchDistrictsResults(foundDistricts.slice(0, 5));
    } else {
      setSearchRegionsResults([]);
      setSearchDistrictsResults([]);
    }

    setNoSearchResults(value && (!foundRegions.length && !foundDistricts.length));
  };


  const submitSearch = () => {
    const queryParams = [];
    const selectedRegionStr = selectedRegion ? `/${selectedRegion}` : '';
    const selectedDistrictStr = selectedDistrict ? `/${selectedDistrict}` : '';
    let url = `/leje${selectedRegionStr}${selectedDistrictStr}`;

    numberOfGuests && queryParams.push(`guest_capacity=${numberOfGuests}`);
    selectedType && queryParams.push(`categories=${selectedType.categories.join(',')}`);

    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    };

    return url;
  };


  const searchResultsComponent =
    <div className="places-search__search-results">
      { !!searchRegionsResults.length &&
        <div className="places-search__search-results-list">
          <div className="places-search__search-results-list-headline">
            Landsdele
          </div>
          { searchRegionsResults.map(region =>
            <div className="places-search__search-results-list-item dropdown__option" onClick={() => selectRegion(region)} key={region.id}>
              <div className="places-search__search-results-item-label">
                { region.name }
              </div>
              <div className="places-search__search-results-item-count">
                { region.placesCount } stk.
              </div>
            </div>
          )}
        </div>
      }
      { !!searchDistrictsResults.length &&
        <div className="places-search__search-results-list regions">
          <div className="places-search__search-results-list-headline">
            Områder
          </div>
          { searchDistrictsResults.map(district =>
            <div className="places-search__search-results-list-item dropdown__option" onClick={() => selectDistrict(district)} key={district.id}>
              <div className="places-search__search-results-item-label district">
                { district.slug.split('-').join(' ') }
              </div>
              <div className="places-search__search-results-item-count">
                { district.placesCount } stk.
              </div>
            </div>
          )}
        </div>
      }
      { noSearchResults &&
        <div className="dropdown__no-results">
          <div className="info-tooltip-wrapper"
               data-tooltip-content="Din søgning giver desværre ingen resultater. Prøv eventuelt at søge på et postnummer eller en landsdel."
               data-tooltip-class-name="info-tooltip"
               data-tooltip-place="right-start"
               data-tooltip-variant="light"
               data-tooltip-id="no-results-tooltip"
               role="clickable">
            <SvgIcon name="info" size={20} />
          </div>
          <Tooltip
            border="1px solid #E8753D"
            effect="solid"
            id="no-results-tooltip"
            opacity={1}
            place="bottom"
            type="light" />
          Intet resultat fundet.
        </div>
      }
    </div>;

  const showSearchResults = !!(noSearchResults || searchRegionsResults.length || searchDistrictsResults.length);

  const searchLink = submitSearch();


  return (
    <div className="places-search" ref={wrapperRef || null}>

      <BasicDropdown
        bodyTitle="Vælg lokaletype"
        handleChange={(value) => setSelectedType(value)}
        options={typesOptions}
        placeholder={'Lokaletype'}
        triggerIcon="home_work"
        wrapperClass={`places-search__form-group types`}
      />

      <DropdownWithSearch
        bodyTitle={noSearchResults ? "Andre populære muligheder" : !showSearchResults ? "Vælg et postnummer eller by" : null}
        handleSelect={selectRegion}
        handleSearch={(value) => handleSearchInput(value)}
        inputValue={inputValue}
        options={regionsOptions}
        placeholder={'Postnummer eller By'}
        searchIcon={<SvgIcon name="map_pin" size={18} />}
        searchResultsComponent={searchResultsComponent}
        showDefaultOptions={noSearchResults}
        showSearchResults={showSearchResults}
        wrapperClass={`places-search__form-group regions`}
      />

      <NumberDropdown
        bodyLabel="Antal gæster"
        id="guests_number"
        handleChange={setNumberOfGuests}
        label="gæster"
        placeholder="Antal"
        triggerIcon="users2"
        value={numberOfGuests}
        wrapperClass={`places-search__form-group guests-number`}
      />

      <a href={searchLink} className="places-search__submit-button" >
        <SvgIcon name="search" size={16} />
        <span>Søg efter steder</span>
      </a>

      <div className="places-search__active-background" />
    </div>
  )
}

export default PlacesSearch;
